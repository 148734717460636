.footer {
  border-top: 14px solid #232346;
  &_contact-wrapper {
    display: flex;
    justify-content: space-between;
    background: #e1e1e3;
    padding: 10px;
    font-size: 11px;
    color: #000;
    flex-direction: column;
    @media (min-width: 400px) {
      padding: 10px;
    }
    @media (min-width: 460px) {
      padding: 15px 20px;
    }
    @media (min-width: 1335px) {
      flex-direction: row;
      align-items: center;
    }
    @media (min-width: 1500px) {
      padding: 17px 50px 25px;
    }
    a {
      color: #000;
      display: block;
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_logos {
    margin-top: 35px;
    @media (min-width: 1335px) {
      margin-top: 15px;
    }
  }
}
