@import './variables.scss';
@import './normalize.scss';
@import './components/all.scss';

.container {
  padding: 0 20px;
  @media (min-width: 1500px) {
    padding: 0 50px;
  }
}

#site-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto !important;
  height: 100%;
}

.site-content-wrapper {
  @media (min-width: 1100px) {
    display: flex;
    flex-grow: 1;
  }
}

.site-content {
  flex-grow: 1;
  padding: 15px 10px 20px;
  @media (min-width: 460px) {
    padding: 20px 20px 30px;
  }
  @media (min-width: 1500px) {
    padding: 30px 50px;
  }
}

.logo {
  border-bottom: 14px solid #232346;
  background: #e3e5e9;
  padding: 10px;
  @media (min-width: 460px) {
    padding: 12px 20px 10px;
  }

  @media (min-width: 1500px) {
    padding: 14px 50px 10px;
    img {
      width: 202px;
      height: 80px;
    }
  }
}

.pic {
  font-size: 0;
  line-height: 0;
}

.block-title {
  font-size: 18px;
  font-weight: bold;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.advertising {
  margin: 0 -10px;
  &_item {
    border-radius: 9px;
    overflow: hidden;
    margin: 0 10px 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (min-width: 460px) {
    &_item {
      margin-bottom: 10px;
    }
  }
}

.logos {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  img {
    margin-bottom: 20px;
    &:nth-child(1) {
      width: 172px;
      height: 102px;
    }
    &:nth-child(2) {
      width: 209px;
      height: 78px;
    }
    &:nth-child(3) {
      width: 45px;
      height: 94px;
    }
    &:nth-child(4) {
      width: 153px;
      height: 56px;
      margin-bottom: 0;
    }
  }
  @media (min-width: 560px) {
    flex-direction: row;
    align-items: flex-end;
    img {
      margin: 0;
      margin-right: 10px;
      &:nth-child(1) {
        width: 140px;
        height: 86px;
      }
      &:nth-child(2) {
        width: 180px;
        height: 61px;
      }
      &:nth-child(3) {
        height: 88px;
      }
      &:nth-child(4) {
        width: 130px;
        height: 52px;
        margin-right: 0;
      }
    }
  }

  @media (min-width: 820px) {
    justify-content: flex-start;
    img {
      margin-right: 20px;
      &:nth-child(1) {
        width: 146px;
        height: 86px;
      }
      &:nth-child(2) {
        width: 196px;
        height: 67px;
      }
      &:nth-child(3) {
        width: 50px;
        height: 99px;
      }
      &:nth-child(4) {
        width: 167px;
        height: 58px;
      }
    }
  }

  @media (min-width: 1000px) {
    img {
      &:nth-child(1) {
        width: 156px;
        height: 98px;
      }
      &:nth-child(2) {
        width: 198px;
        height: 68px;
      }
      &:nth-child(3) {
        height: 108px;
      }
      &:nth-child(4) {
        width: 183px;
        height: 66px;
      }
    }
  }

  @media (min-width: 1200px) {
    img {
      &:nth-child(1) {
        width: 182px;
        height: 110px;
      }
      &:nth-child(2) {
        width: 227px;
        height: 88px;
      }
      &:nth-child(3) {
        width: 50px;
        height: 110px;
      }
      &:nth-child(4) {
        width: 203px;
        height: 86px;
      }
    }
  }
}

.contact {
  &_title {
    margin-bottom: 15px;
    text-align: center;
  }
  &_items {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  &_item {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    &-title {
      margin-bottom: 10px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: 560px) {
    &_title {
      text-align: left;
    }
    &_items {
      justify-content: space-between;
      flex-direction: row;
    }
    &_item {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0;
      &-title {
        margin: 0;
        margin-right: 30px;
      }
    }
    &_item-body {
      text-align: right;
    }
  }
  @media (min-width: 820px) {
    &_items {
      width: 500px;
    }
  }
}

.copyright {
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: #2e8486;
  font-size: 10px;
  &_logo {
    margin-right: 2px;
    margin-bottom: 10px;
    img {
      width: 72px;
      height: 20px;
    }
  }

  @media (min-width: 460px) {
    padding: 15px 20px;
  }

  @media (min-width: 560px) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    font-size: 12px;
    &_logo {
      margin-bottom: 0;
    }
  }

  @media (min-width: 1000px) {
    &_logo {
      img {
        width: 105px;
        height: 31px;
      }
    }
  }

  @media (min-width: 1200px) {
    &_logo {
      img {
        width: 130px;
        height: 37px;
      }
    }
  }

  @media (min-width: 1500px) {
    padding: 14px 50px 20px;
  }
  &_text {
    position: relative;
    bottom: -1px;
    margin-left: 10px;
    span {
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

.insignificant {
  .block-title {
    background: #20ac16;
  }
}

.moderate {
  .block-title {
    background: #dac21f;
  }
}

.severe.form {
  .block-title {
    background: #da8e1b;
  }
}

.critical {
  .block-title {
    background: #e64b27;
  }
}

.expert {
  .block-title {
    background: #232346;
  }
}
