.case-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media (min-width: 860px) {
    justify-content: space-between;
  }
  &--loading {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    .lds-ring {
      margin-top: -20px;
    }
  }
}

._case-list {
  margin-bottom: 10px;
}
