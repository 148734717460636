.content {
  &_item {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    &-title {
      margin-bottom: 12px;
      @media (min-width: 860px) {
        margin-bottom: 20px;
      }
    }
    &-sub-title {
      font-weight: bold;
    }
    &-body {
      p {
        margin: 0;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
