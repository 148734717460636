.languages {
  display: flex;
  align-items: center;
  &_link {
    width: 30px;
    line-height: 30px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background: #232346;
    margin-right: 10px;
    width: 24px;
    line-height: 24px;
    &:last-child {
      margin-right: 0;
    }
    @media (min-width: 400px) {
      width: 26px;
      line-height: 26px;
    }
    @media (min-width: 460px) {
      width: 28px;
      line-height: 28px;
    }
    @media (min-width: 1500px) {
      width: 30px;
      line-height: 30px;
    }
  }
  &_link--active {
    background: #3f7cc8;
    color: #ffffff;
  }
}
