.right-column {
  background: #f6f6f7;
  padding: 10px;
  @media (min-width: 560px) {
    padding: 20px 20px;
  }
  @media (min-width: 1100px) {
    width: 230px;
    flex-shrink: 0;
    padding: 20px 10px;
  }
  .right-column_item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @media (min-width: 460px) {
      justify-content: space-between;
    }
  }
}
