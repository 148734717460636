._description {
  margin-bottom: 20px;
  @media (min-width: 1300px) {
    margin-bottom: 30px;
  }
  @media (min-width: 1500px) {
    margin-bottom: 40px;
  }
}

.description_title {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
  @media (min-width: 460px) {
    font-size: 25px;
  }
  @media (min-width: 800px) {
    font-size: 30px;
  }
  @media (min-width: 1300px) {
    margin-bottom: 25px;
  }
  @media (min-width: 1500px) {
    margin-bottom: 30px;
  }
}

.description_body {
  &-title {
    margin-bottom: 20px;
  }
  &-items {
    margin-left: 10px;
  }
  &-item {
    padding-left: 20px;
    position: relative;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #707070;
    }
  }
}
