.case-list_item {
  margin-bottom: 15px;
  @media (min-width: 460px) {
    margin-bottom: 22px;
  }
}

.case-item {
  width: 100%;
  background: #f3f1f1;
  border-radius: 7px;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  color: $text_color;
  &_title {
    padding: 10px;
    background: #9a1d9a;
    text-align: center;
    width: 100%;
    color: #fff;
    @media (min-width: 460px) {
      padding: 20px;
    }
  }
  &_body {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    height: 100%;
    width: 100%;
    @media (min-width: 560px) {
      padding: 0 20px;
    }
  }
  &_body-desc {
    margin-bottom: 10px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    &--empty {
      text-transform: uppercase;
      font-size: 20px;
      margin-bottom: 0;
      padding: 10px;
    }
  }
  &_body-pic {
    width: 100%;
    img {
      max-width: 130px;
      max-height: 130px;
      object-fit: contain;
    }
  }
  &_body-name {
    padding: 14px 0 14px;
    font-size: 15px;
    font-weight: bold;
    color: #49414c;
    span {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        border-bottom: 2px solid #49414c;
      }
    }
  }
  @media (min-width: 720px) {
    width: 80%;
  }
  @media (min-width: 860px) {
    width: 48%;
  }
}
