.header {
  position: relative;
  &_languages {
    position: absolute;
    top: 14px;
    right: 10px;
  }
  &_logo {
    img {
      width: 173px;
      height: 71px;
    }
  }
}
